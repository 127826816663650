<template>
  <nav class="bg-gray-800">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <span class="py-2 text-sm font-bold text-gray-100">
              {{ translation.textA }}
            </span>
          </div>
        </div>

        <!-- Error buttons -->
        <div
          v-show="displayErrors.show"
          class="flex justify-end px-2 py-2 w-auto bg-gray-700 rounded-full"
        >
          <button
            v-show="displayErrors.danger"
            type="button"
            @click.prevent="$emit('show-error', 'error', 1)"
            class="mx-1 font-bold bg-red-500 text-gray-100 hover:bg-red-600 hover:text-gray-100 text-sm rounded-full w-6 h-6"
          >
            !
          </button>
          <button
            v-show="displayErrors.warning"
            type="button"
            @click.prevent="$emit('show-error', 'error', 2)"
            class="mx-1 font-bold bg-orange-400 text-gray-100 hover:bg-orange-500 hover:text-gray-100 text-sm rounded-full w-6 h-6"
          >
            !
          </button>
          <button
            v-show="displayErrors.disabled"
            type="button"
            @click.prevent="$emit('show-error', 'error', 3)"
            class="mx-1 font-bold bg-gray-500 text-gray-100 hover:bg-gray-600 hover:text-gray-100 text-sm rounded-full w-6 h-6"
          >
            !
          </button>
        </div>

        <div class="hidden md:block">
          <div class="ml-4 flex items-baseline md:ml-6">
            <button
              @click.prevent="$emit('show-modal', 'faq')"
              class="px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
            >
              {{ translation.faq.textA }}
            </button>
            <button
              @click.prevent="$emit('show-modal', 'disclaimer')"
              class="px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
            >
              {{ translation.disclaimer.textA }}
            </button>
            <div class="ml-3 relative">
              <div>
                <select
                  id="location"
                  @change="$emit('change-language', $event.target.value)"
                  class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                >
                  <option
                    v-for="language in languageOptions"
                    :key="language.id"
                    :value="language.id"
                    :class="{ 'is-active': language.id == languageId }"
                    class="navbar-item"
                  >
                    {{ language.full }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button
            @click.prevent="toggleNav"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
          >
            <!-- Menu open: "hidden", Menu closed: "block" -->
            <svg
              class="block h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!-- Menu open: "block", Menu closed: "hidden" -->
            <svg
              class="hidden h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div :class="toggleMobileNav" class="md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <button
          @click.prevent="$emit('show-modal', 'faq')"
          class="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
        >
          {{ translation.faq.textA }}
        </button>
        <button
          @click.prevent="$emit('show-modal', 'disclaimer')"
          class="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
        >
          {{ translation.disclaimer.textA }}
        </button>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700 px-4 md:px-0">
        <div>
          <select
            id="location"
            @change="$emit('change-language', $event.target.value)"
            class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
          >
            <option
              v-for="language in languageOptions"
              :key="language.id"
              :value="language.id"
              :class="{ 'is-active': language.id == languageId }"
              class="navbar-item"
              @change="$emit('change-language', language.id)"
            >
              {{ language.full }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Navigation",
  props: {
    languageId: Number,
    languageOptions: Array,
    translation: Object,
  },

  data: function () {
    return {
      displayOnMobile: false,
    };
  },

  methods: {
    toggleNav() {
      console.log(this.displayOnMobile);
      this.displayOnMobile = !this.displayOnMobile;
    },
  },

  computed: {
    ...mapGetters(["displayErrors"]),

    toggleMobileNav() {
      if (this.displayOnMobile) {
        return {
          block: true,
          hidden: false,
        };
      }
      return {
        block: false,
        hidden: true,
      };
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1100px) {
  .fixed-nav {
    position: fixed;
  }
}
</style>