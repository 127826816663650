<template>
  <li :class="checkPossibility" class="col-span-1 bg-white rounded-md shadow">
    <div
      class="w-full flex items-center justify-between px-6 py-3 space-x-6 bg-gray-800 rounded-t-md"
    >
      <div class="flex-1 truncate">
        <div class="flex justify-between items-baseline space-x-3">
          <h3 class="text-gray-100 text-sm leading-5 font-bold truncate">
            {{ model.name }}
          </h3>

          <button
            type="button"
            @click.prevent="$emit('show-modal')"
            class="ml-2 font-bold bg-gray-500 text-gray-100 hover:bg-blue-400 hover:text-gray-100 text-sm rounded-full w-6 h-6"
          >
            i
          </button>
        </div>
      </div>
    </div>
    <table class="min-w-full mb-3">
      <thead>
        <tr class="border-t border-gray-200">
          <th class="px-2 md:px-6 py-1 border-b border-gray-200 bg-gray-50">&nbsp;</th>
          <th
            class="px-2 py-1 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 tracking-wider"
          >
            horizontal
          </th>
          <th
            class="px-2 py-1 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 tracking-wider"
          >
            vertical
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="px-2 md:px-6 py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
          >
            {{ translation.minId.textB }} ø (mm)
          </td>
          <td
            :class="checkMinIdHorizontal"
            class="py-1 my-2 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 text-center"
          >
            {{ model.horizontal.minId }}
          </td>
          <td
            :class="checkMinIdVertical"
            class="py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 text-center"
          >
            {{ model.vertical.minId }}
          </td>
        </tr>
        <tr>
          <td
            class="px-2 md:px-6 py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
          >
            {{ translation.maxOd.textB }} ø (mm)
          </td>
          <td
            :class="checkMaxOdHorizontal"
            class="py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 text-center"
          >
            {{ model.horizontal.maxOd }}
          </td>
          <td
            :class="checkMaxOdVertical"
            class="py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 text-center"
          >
            {{ model.vertical.maxOd }}
          </td>
        </tr>
        <tr>
          <td
            class="px-2 md:px-6 py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
          >
            {{ translation.maxWidth.textB }} (mm)
          </td>
          <td
            :class="checkMaxWidthHorizontal"
            class="py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 text-center"
          >
            {{ model.horizontal.maxWidth }}
          </td>
          <td
            :class="checkMaxWidthVertical"
            class="py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 text-center"
          >
            {{ model.vertical.maxWidth }}
          </td>
        </tr>
        <tr>
          <td
            class="px-2 md:px-6 py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
          >
            {{ translation.maxWeight.textB }} (kg)
          </td>
          <td
            colspan="3"
            :class="checkMaxWeight"
            class="py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 text-center"
          >
            {{ model.maxWeight }}
          </td>
        </tr>
        <tr>
          <td
            class="px-2 md:px-6 py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900"
          >
            {{ translation.maxTemp.textB }} (°C)
          </td>
          <td
            colspan="3"
            :class="checkMaxTemp"
            class="py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 text-center"
          >
            {{ model.maxTemp }}
          </td>
        </tr>
      </tbody>
    </table>
  </li>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Result",

  props: {
    translation: Object,
    model: Object,
  },

  watch: {
    formValues() {
      this.checkErrorState();
    },
  },

  methods: {
    ...mapActions(["updateModelErrorState"]),

    checkErrorState() {
      const errors = {
        disabled: false,
        danger: false,
        warning: false,
      };

      // Check for disabled (grey)
      if (this.checkPossibility?.isTransparent) {
        errors.disabled = true;
      }

      // Check for warnings (red)
      if (
        this.checkMinIdHorizontal.isDanger ||
        this.checkMinIdVertical.isDanger ||
        this.checkMaxOdHorizontal.isDanger ||
        this.checkMaxOdVertical.isDanger ||
        this.checkMaxWidthHorizontal.isDanger ||
        this.checkMaxWidthVertical.isDanger ||
        this.checkMaxWeight.isDanger ||
        this.checkMaxTemp.isDanger
      ) {
        errors.danger = true;
      }

      // Check for warnings (orange)
      if (
        this.checkMaxOdVertical.isWarning ||
        this.checkMaxWidthHorizontal.isWarning ||
        this.checkMaxTemp.isWarning
      ) {
        errors.warning = true;
      }

      this.updateModelErrorState({
        id: this.model.name,
        errorStates: errors,
      });
    },
  },

  computed: {
    ...mapState(["form"]),

    formValues() {
      return Object.values(this.form);
    },

    checkMinIdHorizontal() {
      return {
        isDanger:
          this.form.minId != 0 && this.model.horizontal.minId > this.form.minId,
      };
    },

    checkMinIdVertical() {
      return {
        isDanger:
          this.form.minId != 0 && this.model.vertical.minId > this.form.minId,
      };
    },

    checkMaxOdHorizontal() {
      return { isDanger: this.model.horizontal.maxOd < this.form.maxOd };
    },

    checkMaxOdVertical() {
      if (this.model.vertical.maxOd < this.form.maxOd) {
        if (this.model.extMeasurements) {
          return {
            isWarning:
              this.model.vertical.maxOd < this.form.maxOd &&
              this.model.extMeasurements.maxOdVert >= this.form.maxOd,
            isDanger: this.model.extMeasurements.maxOdVert < this.form.maxOd,
          };
        } else {
          return {
            isWarning: false,
            isDanger: this.model.vertical.maxOd < this.form.maxOd,
          };
        }
      }
      return {
        isWarning: false,
        isDanger: false,
      };
    },

    checkMaxWidthHorizontal() {
      if (this.model.horizontal.maxWidth < this.form.maxWidth) {
        if (this.model.extMeasurements) {
          return {
            isWarning:
              this.model.horizontal.maxWidth < this.form.maxWidth &&
              this.model.extMeasurements.maxWidthHor >= this.form.maxWidth,
            isDanger:
              this.model.extMeasurements.maxWidthHor < this.form.maxWidth,
          };
        } else {
          return {
            isWarning: false,
            isDanger: this.model.horizontal.maxWidth < this.form.maxWidth,
          };
        }
      }
      return {
        isWarning: false,
        isDanger: false,
      };
    },

    checkMaxWidthVertical() {
      return { isDanger: this.model.vertical.maxWidth < this.form.maxWidth };
    },

    checkMaxWeight() {
      return { isDanger: this.model.maxWeight < this.form.maxWeight };
    },

    checkMaxTemp() {
      if (this.model.maxTemp < this.form.maxTemp) {
        if (this.form.maxTemp <= 400) {
          return { isWarning: true, isDanger: false };
        } else {
          return { isWarning: false, isDanger: true };
        }
      }
      return { isWarning: false, isDanger: false };
    },

    checkPossibility() {
      return {
        isTransparent:
          (this.checkMinIdHorizontal.isDanger &&
            this.checkMinIdVertical.isDanger) ||
          (this.checkMaxOdHorizontal.isDanger &&
            this.checkMaxOdVertical.isDanger) ||
          (this.checkMaxWidthHorizontal.isDanger &&
            this.checkMaxWidthVertical.isDanger) ||
          this.checkMaxWeight.isDanger ||
          this.checkMaxTemp.isDanger,
      };
    },
  },
};
</script>

<style scoped>
.isDanger {
  @apply bg-red-500;
  @apply text-gray-100;
}

.isWarning {
  @apply bg-orange-400;
  @apply text-gray-100;
}

.isTransparent {
  @apply opacity-50;
}
</style>