import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import models from "./json/heaters.json";

const errors = {}
const modelNames = models.heaters.map(model => model.name)

// initializing errors object
modelNames.forEach(modelName => {
    errors[modelName] = {
        disabled: false,
        danger: false,
        warning: false
    }
})

export default new Vuex.Store({
    state: {
        form: {
            maxOd: "",
            minId: "",
            maxWidth: "",
            maxWeight: "",
            maxTemp: "",
          },
        errors,
        modelNames
    },
    getters: {
        displayErrors(state) {
            const isError = status => {
                return !!modelNames.map(model => state.errors[model][status]).filter(s => s).length
            }

            const disabled = isError('disabled')
            const danger = isError('danger')
            const warning = isError('warning')

            return {
                show: disabled || danger || warning,
                disabled,
                danger,
                warning,
            }
        }
    },
    mutations: {
        setErrorStates: (state, { id, errorStates }) => {
            state.errors[id] = errorStates
        },

        setFormFields: (state, form) => {
            state.form = form;
        },
    },
    actions: {
        updateModelErrorState: ({ commit }, { id, errorStates }) => {
            commit('setErrorStates', { id, errorStates })
        },

        updateForm: ({ commit }, updatedForm) => {
            commit('setFormFields', updatedForm)
        },
    }
})