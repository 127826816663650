<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        @click.prevent="$emit('hide-modal')"
        class="fixed inset-0 transition-opacity"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>

      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 mb-15 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6 sm:mb-0"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="sm:flex sm:items-start">
          <div
            v-if="type == 'error'"
            :class="currentErrorType"
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 sm:mt-3 sm:mr-3"
          >
            <!-- Heroicon name: exclamation -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>

          <div class="mt-2 text-center sm:mt-0 sm:ml-1 sm:text-left">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {{ selectedContent.title }}
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                <template v-if="type === 'heater'">
                  <div class="">
                    <div class="mb-4">
                      <img
                        v-bind:src="selectedContent.content.image"
                        class="rounded-md"
                      />
                    </div>
                    <div class="flex justify-between text-xs">
                      <a
                        v-bind:href="selectedContent.content.link"
                        target="_blank"
                        class="block md:inline-block md:w-auto rounded-md px-4 py-2 border border-gray-300 bg-white leading-6 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      >
                        Visit Product Page
                      </a>
                      <a
                        v-bind:href="selectedContent.content.datasheet"
                        target="_blank"
                        class="block md:inline-block md:w-auto rounded-md px-4 py-2 border border-gray-300 bg-white leading-6 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      >
                        Download datasheet (PDF)
                      </a>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <p v-html="selectedContent.content"></p>
                </template>
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              @click.prevent="$emit('hide-modal')"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-800 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Return to Easytherm Selector
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",

  props: {
    translation: Object,
    type: String,
    id: Number,
    models: Array,
    errors: Object,
  },

  computed: {
    selectedContent: function () {
      if (this.type == "heater") {
        return {
          title: this.models[this.id].name,
          content: {
            link: this.models[this.id].link,
            image: this.models[this.id].image,
            datasheet: this.models[this.id].datasheet,
          },
        };
      } else if (this.type == "faq") {
        return {
          title: this.translation.faq.textA,
          content: this.translation.faq.textB,
        };
      } else if (this.type == "disclaimer") {
        return {
          title: this.translation.disclaimer.textA,
          content: this.translation.disclaimer.textB,
        };
      } else if (this.type == "error") {
        if (this.id == 1) {
          return {
            content: this.translation.errors["danger"],
          };
        } else if (this.id == 2) {
          return {
            content: this.translation.errors["warning"],
          };
        } else if (this.id == 3) {
          return {
            content: this.translation.errors["disabled"],
          };
        }
        return {};
      } else {
        return {
          title: this.translation[this.type].textC,
          content: this.translation[this.type].textD,
        };
      }
    },

    currentErrorType: function () {
      if (this.type == "error") {
        if (this.id == 1) {
          return { isDanger: true };
        } else if (this.id == 2) {
          return { isWarning: true };
        } else if (this.id == 3) {
          return { isDisabled: true };
        }
      }
      return "";
    },
  },
};
</script>

<style scoped>
.isDanger {
  @apply bg-red-100;
  @apply text-red-600;
}

.isWarning {
  @apply bg-orange-100;
  @apply text-orange-400;
}

.isDisabled {
  @apply bg-gray-100;
  @apply text-gray-400;
}
</style>