<template>
  <form class="fixed-form px-4 md:px-0 mb-10 md:mb-0">
    <h2 class="pb-4">{{ translation.textB }}</h2>

    <div class="pb-4">
      <div class="flex justify-between items-baseline">
        <label
          for="maxOd"
          class="block text-sm font-bold leading-5 text-gray-700"
          >{{ translation.maxOd.textA }} ø</label
        >
        <button
          tabindex="-1"
          type="button"
          @click.prevent="$emit('show-modal', 'maxOd')"
          class="font-bold bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-600 text-sm rounded-full w-6 h-6"
        >
          ?
        </button>
      </div>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          id="maxOd"
          class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
          inputmode="numeric"
          pattern="[0-9]*"
          type="text"
          min="0"
          max="10000"
          placeholder="0"
          v-model="formState.maxOd"
        />
        <div
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 sm:text-sm sm:leading-5"> mm </span>
        </div>
      </div>
    </div>

    <div class="pb-4">
      <div class="flex justify-between items-baseline">
        <label
          for="minId"
          class="block text-sm font-bold leading-5 text-gray-700"
        >
          {{ translation.minId.textA }} ø
        </label>
        <button
          tabindex="-1"
          type="button"
          @click.prevent="$emit('show-modal', 'minId')"
          class="font-bold bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-600 text-sm rounded-full w-6 h-6"
        >
          ?
        </button>
      </div>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          id="minId"
          class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
          inputmode="numeric"
          pattern="[0-9]*"
          type="text"
          min="0"
          max="10000"
          placeholder="0"
          v-model="formState.minId"
        />
        <div
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 sm:text-sm sm:leading-5"> mm </span>
        </div>
      </div>
    </div>

    <div class="pb-4">
      <div class="flex justify-between items-baseline">
        <label
          for="maxWidth"
          class="block text-sm font-bold leading-5 text-gray-700"
        >
          {{ translation.maxWidth.textA }}
        </label>
        <button
          tabindex="-1"
          type="button"
          @click.prevent="$emit('show-modal', 'maxWidth')"
          class="font-bold bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-600 text-sm rounded-full w-6 h-6"
        >
          ?
        </button>
      </div>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          id="maxWidth"
          class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
          inputmode="numeric"
          pattern="[0-9]*"
          type="text"
          min="0"
          max="10000"
          placeholder="0"
          v-model="formState.maxWidth"
        />
        <div
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 sm:text-sm sm:leading-5"> mm </span>
        </div>
      </div>
    </div>

    <div class="pb-4">
      <div class="flex justify-between items-baseline">
        <label
          for="maxWeight"
          class="block text-sm font-bold leading-5 text-gray-700"
          >{{ translation.maxWeight.textA }}</label
        >
        <button
          tabindex="-1"
          type="button"
          @click.prevent="$emit('show-modal', 'maxWeight')"
          class="font-bold bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-600 text-sm rounded-full w-6 h-6"
        >
          ?
        </button>
      </div>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          id="maxWeight"
          class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
          inputmode="numeric"
          pattern="[0-9]*"
          type="text"
          min="0"
          max="10000"
          placeholder="0"
          v-model="formState.maxWeight"
        />
        <div
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 sm:text-sm sm:leading-5"> kg </span>
        </div>
      </div>
    </div>

    <div class="pb-4">
      <div class="flex justify-between items-baseline">
        <label
          for="maxTemp"
          class="block text-sm font-bold leading-5 text-gray-700"
          >{{ translation.maxTemp.textA }}</label
        >
        <button
          tabindex="-1"
          type="button"
          @click.prevent="$emit('show-modal', 'maxTemp')"
          class="font-bold bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-600 text-sm rounded-full w-6 h-6"
        >
          ?
        </button>
      </div>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          id="maxTemp"
          class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
          inputmode="numeric"
          pattern="[0-9]*"
          type="text"
          min="0"
          max="10000"
          placeholder="0"
          v-model="formState.maxTemp"
        />
        <div
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 sm:text-sm sm:leading-5"> °C </span>
        </div>
      </div>
    </div>

    <span class="inline-flex rounded-md shadow-sm">
      <button
        @click.prevent="resetForm"
        class="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:bg-gray-100 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
      >
        <svg
          class="-ml-0.5 mr-2 h-4 w-4 fill-current"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="icon-shape">
            <path
              d="M10,3 C6.13400675,3 3,6.13400675 3,10 C3,11.9329966 3.78350169,13.6829966 5.05025253,14.9497475 L6.46446609,13.5355339 C5.55964406,12.6307119 5,11.3807119 5,10 C5,7.23857625 7.23857625,5 10,5 L10,3 L10,3 Z M14.9497475,5.05025253 C16.2164983,6.31700338 17,8.06700338 17,10 C17,13.8659932 13.8659932,17 10,17 L10,15 C12.7614237,15 15,12.7614237 15,10 C15,8.61928813 14.4403559,7.36928813 13.5355339,6.46446609 L14.9497475,5.05025253 L14.9497475,5.05025253 Z M10,20 L6,16 L10,12 L10,20 L10,20 Z M10,8 L14,4 L10,0 L10,8 L10,8 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </svg>
        {{ translation.textC }}
      </button>
    </span>
    <hr class="mt-6 block sm:hidden border-solid" />
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'InputForm',

  props: {
    translation: Object,
  },

  watch: {
    formValues() {
      this.updateForm(this.formState)
    },
  },

  data() {
    return {
      formState: {
        maxOd: '',
        minId: '',
        maxWidth: '',
        maxWeight: '',
        maxTemp: '',
      },
    }
  },

  computed: {
    ...mapState(['form']),

    formValues() {
      return Object.values(this.formState)
    },
  },

  methods: {
    ...mapActions(['updateForm']),

    resetForm() {
      const blankForm = {}

      Object.keys(this.formState).forEach((field) => {
        blankForm[field] = ''
      })

      this.formState = blankForm
    },
  },
}
</script>

<style scoped>
@media only screen and (min-width: 1100px) {
  .fixed-form {
    position: fixed;
  }
}
</style>