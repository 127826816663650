<template>
  <div id="app">
    <modal
      v-if="displayModal.show"
      v-on:hide-modal="toggleModal"
      :translation="selectedLanguage"
      :models="models"
      :type="displayModal.type"
      :id="displayModal.id"
    />
    <div class="mt-0 fixed w-full z-10 top-0 shadow-md">
      <navigation
        v-on:show-modal="toggleModal"
        v-on:change-language="changeLanguageId"
        v-on:show-error="toggleModal"
        :languageId="languageId"
        :languageOptions="languageOptions"
        :translation="selectedLanguage"
      />
    </div>
    <div class="mt-20">
      <main>
        <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div class="md:flex mb-4">
            <div class="md:w-1/4 md:mr-10">
              <input-form
                v-on:show-modal="toggleModal"
                :translation="selectedLanguage"
              />
            </div>
            <div class="md:w-3/4">
              <results
                v-on:show-modal="toggleModal"
                v-on:error-state="updateModelErrorState"
                :translation="selectedLanguage"
                :models="models"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Navigation from "./components/Navigation";
import InputForm from "./components/InputForm";
import Results from "./components/Results";
import Modal from "./components/Modal";

import models from "./json/heaters.json";
import languages from "./json/languages.json";

export default {
  name: "App",

  components: {
    Navigation,
    InputForm,
    Results,
    Modal,
  },

  data: function () {
    return {
      models: models.heaters,
      languageId: 0,
      languageOptions: languages.options,
      translations: languages.translations,
      displayModal: {
        show: false,
        type: "",
        id: "",
      },
    };
  },

  computed: {
    ...mapState(["form"]),

    selectedLanguage: function () {
      return this.translations[this.languageId];
    },
  },

  methods: {
    changeLanguageId: function (id) {
      this.languageId = Number(id);
    },

    toggleModal: function (type, id) {
      this.displayModal.type = type;
      this.displayModal.id = id;
      this.displayModal.show = !this.displayModal.show;
    },
  },
};
</script>

<style>
</style>