<template>
  <ul class="px-4 md:px-0 mb-5 md:mb-0 grid grid-cols-1 gap-6 lg:grid-cols-2">
    <result
      v-for="(model, index) in models"
      v-on:show-modal="$emit('show-modal', 'heater', index)"
      :key="model.name"
      :translation="translation"
      :model="model"
    />

    <li class="col-span-1 bg-white rounded-md shadow">
      <div
        class="w-full flex items-center justify-between px-6 py-3 space-x-6 bg-gray-800 rounded-t-md"
      >
        <div class="flex-1 truncate">
          <div class="flex items-center space-x-3">
            <h3 class="text-gray-100 text-sm leading-5 font-bold truncate">
              {{ translation.contact.textA }}
            </h3>
          </div>
        </div>
      </div>

      <div class="min-w-full mb-3 px-6 py-4 text-sm">
        <p>{{ translation.contact.textB }}</p>
        <div class="mt-4">
          <span class="flex w-full rounded-md shadow-sm">
            <a
              :href="translation.contact.link"
              target="_blank"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-800 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              {{ translation.contact.textA }}
            </a>
          </span>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import Result from "../components/Result";

export default {
  name: "Results",

  components: {
    Result,
  },

  props: {
    models: Array,
    translation: Object,
  },
};
</script>

<style scoped>
</style>